import Container from 'components/container'
import Layout from 'components/layout'
import Card from 'components/molecules/card'
import JumpToTopBtn from 'components/molecules/jumpToTopBtn'
import Seo from 'components/molecules/seo'
import ArticleBody from 'components/organisms/articleBody'
import ArticleHeader from 'components/organisms/articleHeader'
import Hero from 'components/organisms/hero'
import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import Share from 'components/organisms/share'
import TableOfContents from 'components/organisms/tableOfContents'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { ContentfulPage } from 'types/graphql-types'

type PageTemplateProps = PageProps & {
  data: {
    contentfulPage: ContentfulPage
  }
}

const PageTemplate = (props: PageTemplateProps): JSX.Element => {
  const { contentfulPage } = props.data
  const {
    title,
    pageType,
    cta,
    heroImage,
    leadingParagraph,
    showTableOfContents,
    body,
    metaDescription,
  } = contentfulPage

  const hasImage = heroImage !== null

  return (
    <Layout>
      <Seo
        title={title}
        imageUrl={
          hasImage
            ? heroImage?.fluid?.src?.replace(/^\/\//, 'https://')
            : undefined
        }
        description={metaDescription?.metaDescription}
        url={props.location.href}
      />
      {pageType === 'Full Width Hero' ? (
        <Hero title={title} image={heroImage.fluid} align="center" cta={cta} />
      ) : (
        <ArticleHeader hasImage={hasImage}>
          <Container>
            <Card size="lg" title={title} image={heroImage} hero share cta={cta}/>
          </Container>
        </ArticleHeader>
      )}

      <ArticleBody hasImage={hasImage}>
        <Container>
          <div className="row">
            <div className="meta page-meta">
              { showTableOfContents && <TableOfContents />}
              <Share />
            </div>
            <div className="content">
              {!hasImage && (
                <div className="tablet-title">
                  <h1 className="title">{title}</h1>
                </div>
              )}
              {leadingParagraph?.leadingParagraph && (
                <p className="lead">{leadingParagraph?.leadingParagraph}</p>
              )}
              {body && renderDocumentToReactComponents(body)}
            </div>
            <div />
            
          </div>
        </Container>
      </ArticleBody>
      <div className="meta page-meta">
        { showTableOfContents && <JumpToTopBtn/>}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContentFulPage($slug: String, $locale: String) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      slug
      pageType
      cta {
        ... on ContentfulLink {
          ...Link
        }
        ... on ContentfulRegionalLink {
          ...RegionalLink
        }
      }
      node_locale
      heroImage {
        fluid {
          ...Image
        }
      }
      leadingParagraph {
        leadingParagraph
      }
      showTableOfContents
      body {
        raw
        references {
          ... on Node {
            ... on ContentfulImagesContentBlock {
              ...ContentfulImagesContentBlock
            }
            ... on ContentfulAsset {
              contentful_id
              title
              description
              fluid {
                ...Image
              }
            }
            ... on ContentfulVideo {
              contentful_id
              internal {
                type
              }
              url
            }
            ... on ContentfulLink {
              contentful_id
              internal {
                type
              }
              link
              title
            }
            ... on ContentfulPage {
              contentful_id
              title
              slug
              internal {
                type
              }
            }
            ... on ContentfulArticle {
              contentful_id
              title
              slug
              internal {
                type
              }
            }
            ... on ContentfulRegionalLink {
              contentful_id
              title
              nzSlug
              auSlug
              ukSlug
              sgSlug
              jpSlug
              internal {
                type
              }
            }
            ... on ContentfulRegionalKlaviyoEmbedForm {
              contentful_id
              title
              jpKlaviyoFormID
              nzKlaviyoFormID
              sgKlaviyoFormID
              ukKlaviyoFormID
              auKlaviyoFormID
              internal {
                type
              }
            }
            ... on ContentfulAccordion {
              contentful_id
              title
              items {
                title
                body {
                  raw
                }
              }
              internal {
                type
              }
            }
            ... on ContentfulTable {
              contentful_id
              title
              hasHeader
              table {
                tableData
              }
              internal {
                type
              }
            }
            ... on ContentfulButton {
              contentful_id
              title
              regionallink: link {
                title
                nzSlug
                auSlug
                ukSlug
                sgSlug
                jpSlug
              }
              internal {
                type
              }
            }
            ... on ContentfulSecondaryButton {
              contentful_id
              title
              regionalLink: link {
                ukSlug
                auSlug
                nzSlug
                jpSlug
                sgSlug
              }
            }
          }
        }
      }
      metaDescription {
        metaDescription
      }
    }
  }
`

export default PageTemplate
